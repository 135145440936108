import * as React from "react";
import '../styles/milestones.scss';

function Milestone(props) {
    return (
        <div className="milestone">
            <div className="label">
                {props.label ? <div className="label-text">{props.label}</div> : ''}
                <div className="label-line"/>
            </div>
            <div className="content-wrapper">
                <h3 className="title">{props.title || ''}</h3>
                <div className="subtitle">{props.subtitle || ''}</div>
                <div className="content">{props.children || ''}</div>
            </div>
        </div>
    )
}

export default function Milestones(props) {
    let milestones = props.milestones || [];
    let sort = props.sort || false;

    if (sort) {
        milestones = milestones.sort((a, b) => {
            return (sort === 'down') ^ (('' + a.key) <  ('' + b.key)) === 0;
        })
    }

    return (
        <div className="milestones">
            {milestones.map(
                (milestone) => <Milestone
                    label={milestone.label}
                    title={milestone.title}
                    subtitle={milestone.subtitle}>
                    {milestone.content}
                </Milestone>)
            }
        </div>
    )
}
