import * as React from "react";

export default function SectionTitle(props) {
    return (
        <h2 className="section-title">
            <span className="highlight highlight-primary">
                {props.children || ''}
            </span>
        </h2>
    )
}
