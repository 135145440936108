import * as React from "react";

export default function Highlight(props) {
    let classes = (props.secondary ? 'highlight-secondary' : 'highlight-primary');
    let content = props.children;
    if (typeof content == 'string') {
        content = <>{
            content.split(' ')
                .map((section, i) => <span className="highlight-word" key={i}>{section}</span>)
                .reduce((accu, elem) => {
                    return accu === null ? [elem] : [...accu, ' ', elem]
                }, null)
        }</>
    }

    return (
        <mark className={classes}>
            {content}
        </mark>
    )
}
