import * as React from "react"
import {StaticImage} from "gatsby-plugin-image"
import Frame from "../components/frame";
import Milestones from "../components/milestones";
import Highlight from "../components/highlight";
import SectionTitle from "../components/section-title";

export default function About() {
    let projects = [
        {
            title: 'SecurePay Payment Extensions',
            content: <>
                <p>
                    Credit Card payment for your website, provided by SecurePay Payment Gateway.
                </p>
                <p>
                    Available for <Highlight secondary>Magento 2</Highlight>, {}
                    <Highlight secondary>WooCommerce</Highlight>, <Highlight secondary>Gravity Form</Highlight> {}
                    and <Highlight secondary>OpenCart 3</Highlight>.
                </p>
            </>,
            key: 100
        },
        {
            title: 'Westpac PayWay Payment Extensions',
            content: <>
                <p>
                    Credit Card payment for your website, provided by Westpac PayWay Payment Gateway.
                </p>
                <p>
                    Available for <Highlight secondary>Magento 2</Highlight>, {}
                    <Highlight secondary>WooCommerce</Highlight> and <Highlight secondary>Gravity Form</Highlight>.
                </p>
            </>,
            key: 110
        },
        {
            title: <><Highlight>A lot of</Highlight> Magento 2 and Wordpress functionality extensions</>,
            subtitle: <>and still counting</>,
            content: <>
                <p>
                    From admin management and connectivity to customer's frontend experience.
                </p>
            </>,
            key: 120
        },
    ];
    let workspaces = [
        {
            label: <>Aug 2019 - Now</>,
            title: <>FGC Techlution</>,
            subtitle: <>Full stack e-commerce developer</>,
            key: 40
        }
    ];

    return (
        <Frame title="About Hieu">
            <header>
                <div className="container mt2">
                    <h1>
                        <Highlight>About me</Highlight>
                    </h1>
                </div>
            </header>
            <section>
                <div className="container">
                    <SectionTitle>
                        What I make
                    </SectionTitle>
                    <Milestones milestones={projects} sort={'down'}/>
                </div>
            </section>
            <section>
                <div className="container">
                    <SectionTitle>
                        Where I work
                    </SectionTitle>
                    <Milestones milestones={workspaces} sort={'down'}/>
                </div>
            </section>

            <section>
                <div className="container">
                    <SectionTitle>
                        How I resolve problems
                    </SectionTitle>
                    <div>
                        I do three simple steps:
                        <ol>
                            <li>Take a look at the problem</li>
                            <li>Try to resolve it</li>
                            <li>
                                If:
                                <ol type="a">
                                    <li>
                                        Problem resolved? Case close.
                                    </li>
                                    <li>
                                        Problem not resolved? Back to step 2.
                                    </li>
                                </ol>
                            </li>
                        </ol>
                    </div>
                </div>
            </section>
        </Frame>
    )
}
